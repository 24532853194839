import template from './sessions.html'

//Instantiate Page Variables 
class ViewSessionsPageViewModel
{
	constructor(page)
	{
		this.page = page;
	}
}

//Handle interaction with page 
class ViewSessionsPage
{
	constructor(bindings)
	{
		this.bindings = bindings;
		this.viewModel = new ViewSessionsPageViewModel(this);
		this.name = 'ViewSessionsPage';
	}

	async init ()
	{
		document.title = 'Dashboard - Sessions';

		const actions_formatter = (value, row, index) => {
			return `
				<a class="logout" title="End Session" href="javascript:void(0)"><i class="fa fa-user-times" style="color: red;"></i></a>&emsp;
				<a class="view" title="View" href="javascript:void(0)"><i class="fa fa-user-times" style="color: blue;"></i></a>&emsp;
			`;
		};
		const actions_events = {
			'click .logout': async (e, value, row, index) => {
				let response = await fetch(`/api/session/logout?session_uuid=${row.session_uuid}`);
				await response.text();
				this.updateData();
			},
			'click .view': (e, value, row, index) => {
				alert(value.headers);
			}
		};

		this.tbl_table = $('#tbl_table').bootstrapTable({
			tablename: 'v_active_sessions',
			schema: 'grape',
			columns: [
				{field: 'username', title: 'Username', sortable: true, width: ''},
				{
					field: 'valid_from', 
					title: 'Session Started', 
					sortable: true, 
					width: '180px', 
					formatter: (v, r, i) => { return moment(v).format(Grape.config.date_time_format); }
				},
				{field: 'email', title: 'Email', sortable: true, width: ''},
				{field: 'ip_address', title: 'IP Address', sortable: true, width: '180px'},
				{field: 'user_agent', title: 'User Agent', sortable: false, width: ''},
				{field: 'auth_methods', title: 'Session Auth Methods', sortable: false, width: ''},
				{field: 'action', width: '100px', align: 'right', title: 'Actions', formatter: actions_formatter, events: actions_events}
			],

			dataField: 'records',

			//TABLE PROPERTIES: Paging
			pagination: true,
			pageList: [20, 50, 100],
			pageSize: 20,
			sidePagination: 'server',
			onPageChange: (number, size) => {
				this.updateData();
			},

			//TABLE PROPERTIES: Sort
			// sortOrder: 'asc',
			// sortName: 'user_id',
			onSort: (sortName, sortOrder) => {
				this.updateData();
			},

			//TABLE PROPERTIES: Search
			search: true,
			searchOnEnterKey: true,
			onSearch: (text) => {
				this.updateData();
			}
		});

	}

	async updateData ()
	{
		let options = $('#tbl_table').bootstrapTable('getOptions');

		let result = await Grape.tables.select(options);
		let records = [];
		for (let r of result.records)
		{
			records.push({
				session_uuid: r.session_uuid,
				username: r.username,
				email: r.email,
				valid_from: r.valid_from,
				auth_methods: r.session_data?.auths,
				user_agent: r.headers['user-agent']
			});
		}
		result.records = records;
		$('#tbl_table').bootstrapTable('load', result);
	}

	teardown ()
	{
	}
}

export default {
	route: '[/grape-ui/grape_settings/]sessions',
	page_class: ViewSessionsPage,
	container: 'divpagecontainer',
	template: template,
	name: 'sessions',
	title: 'Sessions',
	page_id: 'dashboard.sessions',
	icon: 'fa fa-laptop'
};


