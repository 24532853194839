import template from './dashboard.html';

class AdminDashboardVM
{
	constructor (page) {
		this.page = page;

		this.tabs = ko.observableArray(window.dashboard_items); 
		this.current_page_id = ko.observable();
	
	this.click_tab = (tab) => {
		let element = this.page.element.querySelector('#dashboard_page');
		this.current_page_id(tab.page_id);
		Grape.pages.load_page(Grape.pages.find_page_by_id(tab.page_id), element, {}, {}, () => {
			window.localStorage.setItem('last_dashboard_page', tab.page_id);
		});
	};
	}
};

class AdminDashboardPage 
{
	constructor (bindings, element, page) {
		this.viewModel = new AdminDashboardVM(this);
		this.bindings = bindings;
		this.element = element;
	}
	
	async init () {
		document.title = 'Dashboard';

		if (!window.Grape.currentSession)
		{
			let current_hash = encodeURIComponent('/grape-ui/admin/dashboard');
			document.location = '#/grape-ui/login?rr=' + current_hash;
			return;
		}

		let element = this.element.querySelector('#dashboard_page');
		let load_page;
		if (window.localStorage.getItem('last_dashboard_page'))
			load_page = window.localStorage.getItem('last_dashboard_page');
		else
			load_page = 'dashboard.overview';

		this.viewModel.current_page_id(load_page);
		Grape.pages.load_page(Grape.pages.find_page_by_id(load_page), element, {}, {}, () => {
			window.localStorage.setItem('last_dashboard_page', load_page);
		});

	};

	updateData (v,e) {
	};
};

export default {
	route: '[/]grape-ui/admin/dashboard',
	page_class: AdminDashboardPage,
	template: template
};

