import template from './access_roles.html'

class AccessRolesVM
{
	constructor(dialog)
	{
		this.dialog = dialog;
	}
	btnAdd_click()
	{
		this.dialog.btnAdd_click();
	}
}

//Handle Interaction with Page
class AccessRolesPage
{
	constructor(bindings)
	{
		this.viewModel = new AccessRolesVM(this);
		this.access_roles = [];
	}

	async init () {
		document.title = 'Dashboard - Access Roles';

		let roles = await Grape.cache.fetch('AccessRoles');
		let rolenames = roles.map((x) => x.role_name);
		this.access_roles = rolenames;

		const actions_formatter = (value, row, index) => {
			let ret = [
				'<a class="edit" title="Edit" href="javascript:void(0)">',
				'<i class="fa fa-pencil" style="color: #77C4D3"></i>',
				'</a>&emsp;'
			].join('');
			return ret;
		};
		
		const actions_events = {
			'click .edit': (e, value, row, index) => {
				this.EditDialog(row);
			}
		};

		//COMPONENT: Bootstrap Table
		this.tbl_table = $("#tbl_table").bootstrapTable({
			columns: [
				{field: 'role_name', title: 'Role Name', sortable: true},
				{field: 'assigned_roles', title: 'Assigned Parent Roles'},
				{field: 'membership', title: 'Logical Role Membership'},
				{field: 'actions', title: 'Edit', formatter: actions_formatter, events: actions_events}
			],

			dataField: 'records',

			//TABLE PROPERTIES: Paging
			pagination: true,
			pageList: [20, 50, 100],
			pageSize: 20,
			sidePagination: 'server',
			onPageChange: function(number, size) {
				this.updateData();
			},

			//TABLE PROPERTIES: Sort
			// sortOrder: 'asc',
			// sortName: 'user_id',
			onSort: function(sortName, sortOrder) {
				this.updateData();
			},

			//TABLE PROPERTIES: Search
			search: true,
			searchOnEnterKey: true,
			onSearch: function(text) {
				this.updateData();
			}
		});
	}

	async updateData ()
	{
		let options = {
			tablename: 'v_access_roles',
			schema: 'grape',
			offset: 0,
			limit: 20,
			sortfield: 'role_name',
			sortorder: 'ASC'
		};

		let table_options = $("#tbl_table").bootstrapTable('getOptions');

		if (table_options.sortName && table_options.sortName != '')
		{
			options.sortfield = table_options.sortName;
			options.sortorder = table_options.sortOrder.toUpperCase();
		}

		if (table_options.searchText && table_options.searchText != "")
		{
			options.filter = [
				{field: 'role_name', operand: 'ILIKE', value: table_options.searchText + '%'}
			];
		}

		if (table_options.pageNumber && table_options.pageSize)
		{
			options.limit = table_options.pageSize;
			options.offset = (table_options.pageNumber - 1) * options.limit;
		}

		//SERVER: api call to grape to retrieve data from db regarding request params set above
		let result = await Grape.tables.select(options);

		$('#tbl_table').bootstrapTable('load', result.result ? result.result : result);
	}

	btnAdd_click ()
	{
		this.EditDialog(null);
	}
	
	async EditDialog (row)
	{
		let name_editable = false;
		if (!row)
		{
			var row = {role_name: '', membership: [], assigned_roles: []};
			name_editable = true;
		}

		if (!row.assigned_roles)
			row.assigned_roles = [];

		let fields = [
			{name: 'role_name', type: (name_editable ? 'text' : 'text_readonly'), label: 'Role Name', default_value: row['role_name']},
			{name: 'assigned_roles', type: 'multiselect', label: 'Assigned Roles', list: this.access_roles, default_values: row.assigned_roles}
		];

		let data = await Grape.dialog.open('DataInput', {
				fields: fields,
				title: (name_editable ? 'Add new access role' : 'Edit access role'),
				data: row
			},
			{}
		);

		if (!data)
			return;

		let body = {role_name: data.role_name, assigned_roles: data.assigned_roles};
		let response = await fetch('/api/access-role', {method: 'POST', headers: {'content-type': 'application/json'}, body: JSON.stringify(body)});
		let resdata = await response.json();

		if (resdata.status == 'ERROR')
		{
			Grape.alert_api_error(resdata);
			return;
		}
		if (name_editable)
			Grape.alert({type: 'success', message: 'Access role added', title: 'Success'});
		else
			Grape.alert({type: 'success', message: 'Access role updated', title: 'Success'});

		Grape.cache.invalidate('AccessRoles');
		this.updateData();
	}
}

//Assign Page(HTML/JS) to Grape
export default {
	route: '[/grape-ui/grape_settings/]access_roles',
	page_class: AccessRolesPage,
	container: 'divpagecontainer',
	template: template,
	name: 'access_roles',
	title: 'Access Roles',
	page_id: 'dashboard.access_roles',
	icon: 'fa fa-group'

};

