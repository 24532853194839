
import template from './overview.html';

class GrapeOverviewViewModel
{
	constructor(page)
	{
		this.loaded = ko.observable(false);
		this.page = page;

		this.os_string = ko.observable();
		this.cpu_info = ko.observable();
		this.cpu_temp = ko.observable();
		this.mem = ko.observable();
		this.dbinfo = ko.observable();
		this.userinfo = ko.observable();
		this.server_time = ko.observable();
		this.client_time_delta = ko.observable();

		this.process_list = ko.observableArray();
		this.plugin_list = ko.observableArray();

		this.svc_name = ko.observable();
		this.svc_url = ko.observable();
		this.svc_patches = ko.observableArray();
		this.svc_loaded = ko.observable(false);
		
		this.ui_modules = ko.observableArray(window.Grape.modules);

	}

	/*
	view_logfile (process)
	{
		Grape.show_dialog('ViewProcessLogDialog', {process_name: process.name, process_id: process.pid}, {onClose: dialog_close});
	}
	*/

	async stop_process (name, idx)
	{
		let response = await fetch('/api/worker/slot/stop', {
			method: 'POST',
			headers: {'Content-type': 'application/json'},
			body: JSON.stringify({
				cmd: 'stop',
				worker_name: name,
				slot_idx: idx
			})
		});
		let json = await response.json();
		await this.page.updateData();
	}
}

class GrapeOverviewPage
{
	constructor(bindings)
	{
		this.viewModel = new GrapeOverviewViewModel(this);
		this.bindings = bindings;
		this.timer = null;
	}

	async init ()
	{
		document.title = 'Dashboard - Overview';
	}

	async updateData (v,e)
	{
		let d = await Grape.fetches.getJSON('/api/sys/info', {client_time: (new Date()).toISOString(), sections: ['patches']});

		this.viewModel.svc_name(d.public_settings.service_name);
		this.viewModel.svc_url(d.public_settings.url);
		this.viewModel.svc_patches(d.patches);

		this.viewModel.server_time(new Date(d.server_time));
		this.viewModel.client_time_delta(d.client_time_delta);
		
		this.viewModel.svc_loaded(true);

		d = await Grape.fetches.getJSON('/api/sys/status');
		console.log('system status', d);
		if (d.status != 'OK')
		{
			// TODO
			return;
		}

		this.viewModel.dbinfo({
			current_database: d.current_database,
			pg_listening_channels: d.pg_listening_channels,
			version: d.version,
			database_size: d.database_size,
			numbackends: d.numbackends,
			pg_backend_pid: d.pg_backend_pid,
			table_count: d.table_count
		});

		this.viewModel.userinfo({
			app_user_count: d.app_user_count,
			active_app_user_count: d.active_app_user_count,
			session_count: d.session_count
		});


		d = await Grape.fetches.getJSON('/api/sys/info/process');
		if (d.status != 'OK')
		{
			// TODO
			return;
		}
		let proclist = [];
		for (let p of d.manager.process_list)
		{
			let rss = parseInt(p.mem.rss);
			p.mem_str = '' + parseInt(rss / 1024 / 1024) + 'MB';
			proclist.push(p);
		}
		this.viewModel.process_list(proclist);
		this.viewModel.plugin_list(d.manager.plugin_list);
		
		this.viewModel.loaded(true);

	}

	teardown ()
	{
		clearTimeout(this.timer);
	}
}

export default {
	route: '[/grape-ui/dashboard/]overview',
	page_class: GrapeOverviewPage,
	container: 'divpagecontainer',
	template: template,
	name: 'overview',
	title: 'Overview',
	page_id: 'dashboard.overview',
	icon: 'fa fa-info-circle'
};


