import template from './networks.html'

class NetworksPageVM
{
}

class NetworksPage
{
	constructor(bindings)
	{
	}

	init ()
	{
		document.title = 'Dashboard - Networks';

		const actions_formatter = function(value, row, index) {
			let ret = [
				'<a class="edit" title="Edit" href="javascript:void(0)">',
				'<i class="fa fa-pencil" style="color: #77C4D3"></i>',
				'</a>&emsp;',
				'<a class="delete" title="Delete" href="javascript:void(0)">',
				'<i class="fa fa-trash" style="color: red;"></i>',
				'</a>&emsp;'
			].join('');
			return ret;
		};
		
		const actions_events = {
			'click .edit': (e, value, row, index) => {
				this.EditDialog(row);
			},
			'click .delete': (e, value, row, index) => {
				this.DeleteDialog(row);
			}
		};

		this.tbl_table = $("#tbl_table").bootstrapTable({
			columns: [
				{field: 'network_id', title: 'ID', sortable: true, width: '40px'},
				{field: 'description', title: 'Description', sortable: true},
				{field: 'address', title: 'Address', width: '300px'},
				{field: 'action', width: '100px', align: 'right', title: 'Actions', formatter: actions_formatter, events: actions_events}
			],

			dataField: 'records',

			pagination: true,
			pageList: [20, 50, 100],
			pageSize: 20,
			sidePagination: 'server',
			onPageChange: (number, size) => {
				this.updateData();
			},

			onSort: (sortName, sortOrder) => {
				this.updateData();
			},

			search: true,
			searchOnEnterKey: true,
			onSearch: (text) => {
				this.updateData();
			}
		});
	}

	// Update Bootstrap table
	async updateData ()
	{
		let options = {
			tablename: 'network',
			schema: 'grape',
			offset: 0,
			limit: 20,
			sortfield: 'description',
			sortorder: 'ASC'
		};

		let table_options = $("#tbl_table").bootstrapTable('getOptions');

		if (table_options.sortName && table_options.sortName != '')
		{
			options.sortfield = table_options.sortName;
			options.sortorder = table_options.sortOrder.toUpperCase();
		}

		if (table_options.searchText && table_options.searchText != "")
		{
			options.filter = [
				{field: 'description', operand: 'ILIKE', value: table_options.searchText + '%'}
			];
		}

		if (table_options.pageNumber && table_options.pageSize)
		{
			options.limit = table_options.pageSize;
			options.offset = (table_options.pageNumber - 1) * options.limit;
		}

		let result = await Grape.tables.select(options);
		$('#tbl_table').bootstrapTable('load', result);
	}

	btnAdd_click ()
	{
		this.EditDialog(null);
	}

	async DeleteDialog (row)
	{
		if (!(await Grape.confirm('warning', 'Confirm deletion', 'Are you sure you want to delete this network?')))
			return;

		await Grape.tables.delete({schema: 'grape', table: 'network', filter: {network_id: row.network_id}});
		Grape.alert({type: 'success', title: 'Network deleted', message: 'Network was deleted successfuly'});
		this.updateData();
	}
	
	async EditDialog (row)
	{
		let title = 'Add new network';
		if (!row)
			var row = {description: '', address: ''};
		else
			title = 'Edit network';

		let fields = [
			{name: 'description', type: 'text', label: 'Network Description', default_value: ''},
			{name: 'address', type: 'text', label: 'Network Address', default_value: ''}
		];

		let data = await Grape.dialog.open('DataInput', {fields, title, data: row});

		if (!data)
			return;
						
		if (data.network_id)
		{
			await Grape.tables.update({
				schema: 'grape',
				table: 'network',
				values: data,
				returning: 'network_id',
				filter: {network_id: data.network_id}});

			Grape.alert({type: 'success', title: 'Network updated', message: 'Network information was updated successfuly'});
		}
		else
		{
			await Grape.tables.insert({
				schema: 'grape',
				table: 'network',
				values: data,
				returning: 'network_id'
			});
			Grape.alert({type: 'success', title: 'New network added', message: 'Network was added successfuly'});
		}

		this.updateData();
	}
}

export default {
	route: '[/grape-ui/grape_settings/]networks',
	page_class: NetworksPage,
	container: 'divpagecontainer',
	template: template,
	name: 'networks',
	title: 'Networks',
	page_id: 'dashboard.networks',
	icon: 'fa fa-code-fork'
};

