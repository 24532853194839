import template from './users.html'

class UsersPage
{
	constructor (bindings)
	{
		var self = this;
		this.self = self;
	}

	init ()
	{
		document.title = 'Dashboard - Users';

		const status_field_formatter = (value, row, index) => {
			if (row.auth_server)
			{
				return '<span class="fa fa-cloud-upload" title="Remote User"></span>';
			}
			else if (!row.active)
			{
				return '<span class="fa fa-times" title="User Disabled"></span>';
			}
			return '';
		};

		const last_login_formatter = (value, row, index) => {
			if (!row.auth_last_login)
				return 'Never';
			return moment(row.auth_last_login).fromNow();
		};

		const actions_formatter = (value, row, index) => {
			let ret = [];
			if ((row.role_names || []).indexOf('admin') < 0 && (Grape.currentSession.roles || []).indexOf('switch_user') >= 0)
			{
				ret.push([
					'<a class="switch_user" title="Log in as this user" href="javascript:void(0)">',
					'<i class="fa fa-child" style="color: #5B23A0;"></i>',
					'</a>'
					].join(''));
			}

			ret.push([
				'<button class="edit btn btn-warning" title="Edit"><span class="fa fa-pencil">Edit</button>'
			].join(''));

			if (row.active == true)
			{
				ret = ret.concat([
					'<button class="deactivate btn btn-warning"><span class="fa fa-cross">De-Activate</button>'
				].join(''));
			}
			else
			{
				ret = ret.concat([
					'<button class="activate btn btn-warning"><span class="fa fa-cross">Activate</button>'
				].join(''));

			}
			return '<span style="padding-left: 10px;">' + ret.join('') + '</span>';
		};


		const actions_events = {
			'click .reset_password': (event, value, row, index) => {
				this.reset_password(row.username);
			},
			'click .switch_user': async (event, value, row, index) => {
				let obj = {
					guid: row.guid,
					user_id: row.user_id,
					username: row.username
				};
				let response = await fetch(`/api/session/switch-user`, {method: 'POST'});
				let data = await response.json();
				if (d.status == 'ERROR')
				{
					Grape.alert_api_error(d);
				}
				else
				{
					window.location.reload();
				}
			},
			'click .edit': (event, value, row, index) => {
				this.edit_user(row);
			},
			'click .deactivate': async (event, value, row, index) => {
				row.active = false;

				let response = await fetch('/api/user', {
					method: 'POST',
					body: JSON.stringify({
						username: row.username,
						active: row.active
					})
				});
				this.updateData();
			},
			'click .activate': async (event, value, row, index) => {
				row.active = true;
				let response = await fetch('/api/user', {
					method: 'POST',
					body: JSON.stringify({
						username: row.username,
						active: row.active
					})
				});
				this.updateData();

			}
		};


		this.tbl_users = $("#tbl_users").bootstrapTable({
			// Table Settings for GrapeListQuery
			table: 'v_users',
			schema: 'grape',

			// Columns
			columns: [
				{field: 'status', title: '', sort: false, width: '50px', formatter: status_field_formatter},
				{field: 'username', title: 'Username', sortable: true, width: '200px'},
				{field: 'email', title: 'Email', width: '250px'},
				{field: 'otp_methods', title: 'OTP Methods', width: '250px'},
				{field: 'auth_last_login', title: 'Last Login', sort: true, width: '100px', formatter: last_login_formatter},
				{
					field: 'role_names',
					title: 'Roles',
					width: '20%',
					//DISPLAY: Roles in comma delimited format
					formatter: (value, row, index) => {
						var ar = [];
						for (var i = 0; i < (value || []).length; i++)
						{
							var r = value[i];
							if (row.assigned_role_names && row.assigned_role_names.indexOf(r) >= 0)
								ar.push('<span style="font-weight: bold;">' + r + '</span>');
							else
								ar.push('<span style="color: #afafaf; font-style: italic;">' + r + '</span>');
						}
						return ar.join(', ');
					}
				},
				{
					field: 'action',
					width: '20%',
					align: 'right',
					title: 'Actions',
					formatter: actions_formatter,
					events: actions_events
				}
			],

			dataField: 'records',

			//TABLE PROPERTIES: Paging
			pagination: true,
			pageList: [20, 50, 100, 'ALL'],
			pageSize: 20,
			sidePagination: 'server',

			onPageChange: (number, size) => {
				this.updateData();
			},

			//TABLE PROPERTIES: Sort
			// sortOrder: 'asc',
			// sortName: 'user_id',
			onSort: (sortName, sortOrder) => {
				this.updateData();
			},

			//TABLE PROPERTIES: Search
			search_column: 'username',
			search: true,
			searchOnEnterKey: true,
			onSearch: (text) => {
				this.updateData();
			},

			//FEATURE: Expand Row View Details
			detailView: false,
			rowStyle: (value, row, index) => {
				if (value.active == false)
				{
					return {
						css: { "background-color": "#F46E82" }
					};
				}
				if (value.auth_server)
				{
					return {
						css: { "background-color": "#36ACFF" }
					};
				}

				return {classes: ''};
			}
		});
	}

	async updateData ()
	{
		let table_options = $("#tbl_users").bootstrapTable('getOptions');

		let result = await Grape.tables.select(table_options);
		$("#tbl_users").bootstrapTable('load', result);
	}

	btnAddUser_click ()
	{
		this.edit_user(null);
	}

	async reset_password (username)
	{
		let result = await Grape.dialog.open('DataInput',
			{
				fields: [{name: 'new_password', type: 'password', label: 'New Password'}],
				title: 'Reset Password',
				message: 'Please provide password for ' + username,
				data: {}
			});

		let response = await fetch('/api/user', {
			method: 'POST',
			body: JSON.stringify({
				username,
				password: result.new_password
			})
		});
		let data = await response.json();

		if (data.status == 'ERROR')
			Grape.alert_api_error(data);
		else
			Grape.alert({type: 'success', message: 'User password has been reset.'});
	}

	async edit_user (row)
	{
		let user_info = await Grape.dialog.open('UserEdit', row);
		if (!user_info)
			return;

		let response = await fetch('/api/user', {
			method: 'POST',
			body: JSON.stringify(user_info)
		});
		let data = await response.json();

		if (data.status == 'OK')
		{
			if (row)
			{
				Grape.alert(
				{
					type: 'success',
					message:  'Profile details updated',
					title: user_info.username
				});
			}
			else
			{
				Grape.alert(
				{
					type: 'success',
					message:  'New user added',
					title: user_info.username
				});

			}

			this.updateData();
		}
		else
		{
			Grape.alert_api_error(result);
		}
	}
}

export default {
	page_class: UsersPage,
	template: template,
	name: 'users',
	title: 'Users',
	page_id: 'dashboard.users',
	icon: 'fa fa-users'
};


