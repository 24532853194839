import template from './new_password_dialog.html';

/**
 * @class
 * @name NewPasswordDialogViewModel
 * @param {object} page - Page to which this ViewModel belongs
 */
class NewPasswordViewModel {
	constructor (dialog) {
		this.dialog = dialog;
	
		this.show_totp = ko.observable(false);
		this.otp = ko.observable();
		this.password1 = ko.observable('');
		this.password2 = ko.observable('');

		this.error_message = ko.observable('');
	}

	async btnSave_click()
	{
		this.error_message('');
		var p1 = this.password1();
		var p2 = this.password2();

		if (p1.length < 6){
			this.error_message('Password must be longer than 6 characters');
			return;
		}

		if (p1 != p2){
			this.error_message('The two passwords you entered does not match');
			return;
		}

		/*$.postJSON('/api/user', {uuid: Grape.currentSession.user.uuid, password: p1, is_hashed: false}, (ret) => {
			if (ret.status == 'ERROR')
			{
				Grape.alert_api_error(ret);
				return;
			}
			Grape.alert({type: 'success', title: 'Password Saved', message: 'Your new password has been saved', onClose: () => { this.close(); }});

		});*/
		try {
			let reponse = await fetch('/api/user', 
			{
				method: 'POST',
				body: {uuid: Grape.currentSession.user.uuid, password: p1, is_hashed: false},
				headers: {'content-type': 'application/json'}
			});
			if (reponse.status == 200) {
				Grape.alert({type: 'success', title: 'Password Saved', message: 'Your new password has been saved', onClose: () => { this.dialog.close(); }});
			}
		} catch (err) {
			Grape.alert_api_error(err);
		}

	}

	btnClose_click()
	{
		this.dialog.close(null);
	}
}

/**
 * @class
 * @name NewPasswordDialog
 * @param {object} bindings - Bindings
 */
class NewPasswordDialog
{
	constructor (bindings)
	{
		/*
		Grape.shortcut(this, 'enter', () => {
			this.btnsave_click();
		});
		*/
		this.viewModel = new NewPasswordViewModel(this);
	}

	init () {}
}


export default {
	name: 'NewPassword',
	dialog_class: NewPasswordDialog,
	template: template
};

