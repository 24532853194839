
import template from './setting_edit.html';

/**
 * View Model
 */
class DialogViewModel
{
	/**
	 * ViewModel constructor.
	 * @param {DialogClass} dialog - Dialog class
	 */
	constructor(dialog)
	{
		this.dialog = dialog;

		this.name = ko.observable();
		this.description = ko.observable();
		this.value = ko.observable();

		this.data_types = ko.observableArray([
			{name: 'Boolean', value: 'bool'},
			{name: 'Integer', value: 'integer'},
			{name: 'Text', value: 'text'},
		]);
		this.data_type = ko.observable('');

		this.available_tags = ko.observableArray([{name: 'Hidden', value: 'hidden'}]);
		this.selected_tags = ko.observableArray([]);
	}

	/**
	 * Handle click event for save button
	 */
	/*
	save_click()
	{
		const self = this;//
		let input = {
			name: this.name(), 
			description: this.description(),
			data_type: this.data_type(),
			tags: this.selected_tags(),
			value: this.value()
		};

		$.postJSON('/grape/save_setting', input, function(output) {// fetch
			self.dialog.close(true);
		}).fail(function(jqXHR, textStatus, errorThrown) {
			Grape.alert_xhr_error(jqXHR);
		});
	}*/

	async save_click(){
		let input = {
			name: this.name(), 
			description: this.description(),
			tags: this.selected_tags(),
			value: this.value(),
			data_type: this.data_type()
		};
		
		try {
			let response = await fetch(`/api/setting/${input.name}`,
			{	
				method: 'PATCH',
				body: JSON.stringify(input),
				headers: {'content-type': 'application/json'}
			});
			if (response.status == 200){
				this.dialog.close(true);
			}
		} catch (err) {
			Grape.alert({type:'error', title: 'eroor', message: `error happened ${err.message}`});
		}
	}




	/**
	 * Handle click event for close button
	 */
	close_click()
	{
		this.dialog.close(false);
	}
}

/**
 * Dialog
 */
class DialogClass
{
	/**
	 * constructor
	 * @param {Object} bindings -
	 */
	constructor(bindings, element)
	{
		this.bindings = bindings;
		this.viewModel = new DialogViewModel(this); // Name of the ViewModel defined above
		this.name = 'EditSetting'; // Unique dialog name
		this.element = element;

		if (bindings)
		{
			if (bindings.name)
				this.viewModel.name(bindings.name);
			if (bindings.description)
				this.viewModel.description(bindings.description);
			if (bindings.tags)
				this.viewModel.selected_tags(bindings.tags);
			if (bindings.data_type)
				this.viewModel.data_type(bindings.data_type);
			if (bindings.value)
				this.viewModel.value(bindings.value);
		}
	}

	init () {
		const self = this;
		let init_promises = [];
		/*
		init_promises.push(new Promise(function(resolve, reject) {
			Grape.cache.fetch('PersonTitleLookup', function(person_titles) {
				self.viewModel.titles(person_titles);
				resolve();
			});
		}));
		*/

		$('.select2', this.element).select2();

		return Promise.all(init_promises);
	};
}

export default {
	name: 'EditSetting',
	dialog_class: DialogClass,
	template: template
};

