
import template from './account.html';
import UserSettings from '../lib/UserSettings.js';

/**
 * @constructor
 * @name AccountViewModel
 */
class AccountViewModel 
{
	constructor (page) {
		this.page = page;

		this.UserAccountDialogs = ko.observableArray(window.UserAccountDialogs || []);
		this.UserAccountPages = ko.observableArray(window.UserAccountPages || []);

		this.username = ko.observable();
		this.email = ko.observable();
	
		this.uuid = ko.observable();
		this.pg_role = ko.observableArray();
	
		this.otp_methods = ko.observableArray();
		this.user_settings = ko.observable({});
	
		this.roles_str = ko.observable('');
		this.user_settings = ko.observable();
		this.user_setting_list = ko.pureComputed(() => {
			if (this.user_settings())
				return this.user_settings().get_sorted_array();
			else
				return [];
		});

		this.settings_edit = ko.observable(false);
	}

	/**
	 * Load data
	 */
	async load ()
	{
		let result = await fetch('/api/user/-1');
		let data = await result.json();
		
		let email = data.user.email || '';
		
		this.email(email);
		this.username(data.user.username);
		this.uuid(data.user.uuid);
		this.otp_methods(data.user.auth_info.otp_methods);
		this.roles_str(data.user.user_roles.join(', '));

		this.user_settings(new UserSettings());
		this.user_settings().set_settings(await Grape.cache.fetch('UserSettings'));
		this.user_settings().set_values(data.user.settings);
		this.user_settings.notifySubscribers();
	}


	async btnUserAccountDialog_click (item) {
		const res = await Grape.dialog.open(item.dialog_name, {});
	}

	btnUserAccountPage_click (item) {
		window.Grape.navigate(item.navigate_url);
	}


	btnManageAPICredentials_click () {
		window.Grape.navigate('/grape-users/api-creds');
	}

	async btnSave_click()
	{
		const obj = {
			username: this.username()
		};

		// Load setting values from inputs into obj
		for (let [k, v] of this.user_settings().entries())
			if (v.tags.indexOf('user_save') > -1 && v.input() != v.value)
				obj[k] = v.input();

		try {
			let reponse = await fetch('/api/user', 
			{
				method: 'POST',
				body: JSON.stringify(obj),
				headers: {'content-type': 'application/json'}
			});
			if (reponse.status == 200)
				Grape.alert({type: 'success', title: 'Settings Saved', message: 'Your settings have been saved'});
		} catch (err) {
			console.error(err);
			Grape.alert_api_error(err);
		}

		await this.load();
	}

}

/**
 * @constructor
 * @name AccountPage
 */
class AccountPage 
{
	constructor (bindings) {
		this.bindings = bindings;
		this.viewModel = new AccountViewModel(this);
		this.name = 'AccountPage';
	}
	
	init ()
	{
		document.title = 'User Profile';
	}

	async updateData ()
	{
		try {
			await this.viewModel.load();
		} catch (err) { 
			console.error(err);
			Grape.alert_api_error(err);
		}
	}

	teardown ()
	{
	}

	new_password ()
	{
		Grape.dialog.open('NewPassword');
	}
}

export default {
	route: '[/]grape-ui/my-account',
	page_class: AccountPage,
	template: template
};
