import template from './user_edit.html'
import UserSettings from '../lib/UserSettings.js';

class dashboard_UserEditViewModel {
	constructor(dialog) {
		this.dialog = dialog;

		this.selected_tab = ko.observable();
		this.selected_tab.subscribe( (nv) => {
			window.GrapeUIBS3Utils.set_active_tab('user_dialog_nav', nv);
		});
		this.selected_tab('personal_info');

		this.allRoleNames = ko.observableArray();
		this.availableRoleNames = ko.observableArray();
		this.selectedAvailableRoleNames = ko.observableArray();
		this.selectedCurrentRoleNames = ko.observableArray();
		this.currentRoleNames = ko.observableArray([]);
		this.currentRoleNames.subscribe((nv) => {
			let ar = [];
			if (nv && nv.length)
				ar = this.allRoleNames().filter((i) => { return (nv.indexOf(i) == -1); });
			else
				ar = this.allRoleNames();
			this.availableRoleNames(ar);
		});

		this.availableAuthenticationServers = ko.observableArray();

		this.is_user_loaded = ko.observable(false);

		this.user_id = ko.observable();
		this.enable_username_edit = ko.observable(false);
		this.enable_email_edit = ko.observable(false);

		// "Details" tab
		this.username = ko.observable('');
		this.email = ko.observable('');

		this.user_settings = ko.observable();
		this.user_setting_list = ko.pureComputed(() => {
			if (this.user_settings())
				return this.user_settings().get_sorted_array();
			else
				return [];
		});


		this.fullnames = ko.observable('');
		this.otp_methods = ko.observableArray([]);

		this.new_password = ko.observable('');
		this.active = ko.observable(false);
		this.pending = ko.observable(false);
		this.uuid = ko.observable('');
		this.role_names = ko.observableArray([]);
		this.pg_role = ko.observable('');
		this.otp_methods = ko.observableArray([]);
		this.totp_status = ko.observable('');
		this.totp_enabled = ko.observable(false);

		this.auth_mech = ko.observable('');
		this.availableAuthenticationMethods = ko.observableArray(['local', 'remote']);
		this.auth_service_name = ko.observable('');
		this.auth_service = ko.observable();

		this.sessions = ko.observableArray([]);
		this.api_keys = ko.observableArray([]);

		this.title = ko.computed(() => { 
			return `Editing user ${this.username()}`;
		}, this);
		this.access_roles = ko.observableArray([]);
	}

	async load(user_id)
	{
		this.user_id(user_id);
		let response = await fetch(`/api/user/${user_id}`);
		let data = await response.json();
		if (!data || data.status != 'OK')
		{
			Grape.alert_api_error(data);
			return;
		}

		const user = data.user;
		this.username(user.username);
		this.active(user.active);
		this.email(user.email);
		this.uuid(user.uuid);
		this.currentRoleNames(user.user_roles);

		const user_settings = new UserSettings(user_id);
		user_settings.set_values(user.settings);
		this.user_settings(user_settings);

		this.otp_methods(user.settings?.otp_methods || []);

		this.auth_service_name(user.auth_service_name);

		const auth_info = user.auth_info;
		this.auth_mech(auth_info.auth_mech);
		if (auth_info.auth_mech == 'remote' && auth_info.auth_params)
		{
			let auth_service_uuid = auth_info.auth_params.uuid;
			let item = this.availableAuthenticationServers().find((x) => { return x.service_uuid==auth_service_uuid; });
			if (item)
				this.auth_service(item);
		}

		await [
			this.load_sessions(),
			this.load_api_keys(),
			this.load_user_settings_lookup()
		];
	}

	async load_user_settings_lookup()
	{
		let data = await Grape.cache.fetch('UserSettings');
		this.user_settings().set_settings(data);
		this.user_settings.notifySubscribers();
	}

	async load_sessions()
	{
		let session_data = await Grape.fetches.getJSON('/api/record', {
			table: 'v_active_sessions',
			schema: 'grape',
			filter: [{field: 'user_uuid', operator: '=', value: this.uuid()}]
		});
		if (session_data.hasOwnProperty('records'))
			this.sessions(session_data.records);
	}

	async load_api_keys()
	{
		let data = await Grape.fetches.getJSON('/api/record', {
			table: 'v_api_keys',
			schema: 'grape'
		});
		let records = [];
		for (let r of data.records)
			records.push({
				name: r.name,
				roles: r.attributes?.roles || [],
				description: r.attributes.description,
				date_inserted: r.date_inserted,
				last_used: r.last_used,
				uuid: r.api_key_uuid
			});
		this.api_keys(records);
	}


	btnSave_click () {

		let auth_service = null;
		if (this.auth_service())
		{
			auth_service = {
				name: this.auth_service().service_name,
				uuid: this.auth_service().service_uuid
			};
		}

		// Object for API call
		// TODO validation
		const obj = {
			user_id: this.user_id(),
			username: this.username(),
			email: this.email(),
			active: this.active(),
			pending: this.pending(),
			uuid: this.uuid(),
			role_names: this.currentRoleNames(),
			auth_service_params: auth_service,
			auth_mech: this.auth_mech(),
			password: null,
			otp_methods: this.otp_methods()
		};

		// Load setting values from inputs into obj
		for (let [k, v] of this.user_settings().entries())
			if (v.tags.indexOf('user_save') > -1 && v.input() != v.value)
				obj[k] = v.input();

		// Set new password if changed
		if (this.new_password() != '')
			obj.password = this.new_password();

		if (obj.username == '')
		{
			Grape.alert({type: 'error', title: 'Missing fields', message: 'Username is a compulsary field'});
		}
		else if (obj.uuid == '')
		{
			Grape.alert({type: 'error', title: 'Missing fields', message: 'UUID is a compulsary field'});
		}
		else
		{
			this.dialog.close(obj);
		}
	}

	/**
	 * Remove OTP button 
	 */
	async removeOTP_click(otp_method)
	{
		if (await Grape.confirm({type: 'warning', title: '', message: ''}))
		{
			const new_otp_methods = this.otp_methods().filter((x) => x != otp_method);
			this.otp_methods(new_otp_methods);
		}
	}

	/**
	 * Close button
	 */
	btnClose_click()
	{
		this.dialog.close();
	}

	btnRemoveRoles_click () {
		let curr_roles = this.currentRoleNames();
		let remove_roles = this.selectedCurrentRoleNames();
		curr_roles = curr_roles.filter((i) => { return (!remove_roles.includes(i)); });
		this.currentRoleNames(curr_roles);
	}

	btnAddRoles_click () {
		let curr_roles = this.currentRoleNames();
		if (!curr_roles)
			curr_roles = [];
		this.currentRoleNames(curr_roles.concat(this.selectedAvailableRoleNames()));
	}

};

class UserEditDialog {
	constructor(bindings) {
		this.viewModel = new dashboard_UserEditViewModel(this);
		this.name = 'UserEditDialog'
		this.bindings = bindings;
	}

	async init () {
		let access_roles = await Grape.cache.fetch('AccessRoles');
		let rolenames = [];
		for (let role of access_roles)
			rolenames.push(role.role_name);
		this.viewModel.allRoleNames(rolenames);

		/*
		 * TODO
		$.getJSON('/api/services', {type: 'SAML2 IDP'}, function(data) {
			self.viewModel.availableAuthenticationServers(data.services);
		});
		*/

		if (this.bindings && this.bindings.user_id)
		{
			this.viewModel.load(this.bindings.user_id);
		}
		else
		{
			this.viewModel.currentRoleNames([]);
			this.viewModel.enable_email_edit(true);
			this.viewModel.enable_username_edit(true);
		}
	}
}

export default {
	name: 'UserEdit',
	dialog_class: UserEditDialog,
	template: template
};

