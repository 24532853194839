
import template from './recover_password.html';
// RFC 2822
const ValidEmailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

class RecoverPasswordViewModel
{
	constructor (page)
	{
		this.page = page;
		this.email = ko.observable('');
		this.is_loading = ko.observable(false);
		this.email_invalid = ko.observable(false);
		this.email_notfound = ko.observable(false);
	}

	email_keydown (data, event)
	{
		let keyCode = event.originalEvent ? event.originalEvent.keyCode : 0;

		if (keyCode == 13)
		{
			event.preventDefault();
			
			// prevent double submission
			if (this.is_loading() === true)
				return false;
			
			this.page.btnsend_click();
			return false;
		}
		else
		{
			return true;
		}
	}
}

class RecoverPasswordDialog
{
	constructor(bindings)
	{
		// Create new view model and assign Dialog to page
		this.viewModel = new RecoverPasswordViewModel(this);
		this.name = 'RecoverPassword';
	}

	async btnsend_click ()
	{
		if (this.viewModel.is_loading() === true) 
			return false; 
		
		this.viewModel.email_invalid(false);
		this.viewModel.email_notfound(false);

		let email = (this.viewModel.email() || '').trim();

		if (email == '')
		{
			this.viewModel.email_invalid(true);
		}
		else if (!ValidEmailRegex.test(email))
		{
			this.viewModel.email_invalid(true);
		}
		else
		{
			let result = await Grape.fetches.postJSON('/api/user/password-reset-email', {email});
			if (result.status == 'ERROR')
			{
				this.viewModel.email_notfound(true);
				console.error(result);
				//Grape.alert_api_error(result);
			}
			else
			{
				Grape.alert({
					type: 'success',
					message: 'Further instructions will be emailed to your email address',
					title: 'Password Reset',
					onClose: function (d) {
						this.close();
					}
				});
			}
		}
	}

	btnclose_click ()
	{
		this.close();
	}
}

export default {
	name: 'RecoverPassword',
	dialog_class: RecoverPasswordDialog,
	template: template
}

