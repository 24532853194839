
// DIALOGS
import recover_pw_dialog from './recover_password.js';
import user_edit from './user_edit.js';
import setting_edit from './setting_edit.js';
import view_log from './viewlog.js';
import new_password_dialog from './new_password_dialog';

let dialogs = [
	recover_pw_dialog,
	user_edit,
	setting_edit,
	view_log,
	new_password_dialog
];

export default dialogs;

